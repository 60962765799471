import React, { useEffect, useRef, useState } from 'react';
import {Button, BUTTON_TYPES, BUTTON_VARIANT} from '@klenty/klenty-ui';
import "./clusterSelection.css";
import newLogo from "../../../assets/img/kl_new_logo.png";
import Card from '../../../components/Card/Card';
import axios, { CancelTokenSource } from 'axios';
import { Modal, TextInput, MODAL_SIZES, MODAL_TYPES, TEXT_INPUT_TYPES, Tooltip, TOOLTIP_POSITIONS, showMessageBar, MESSAGE_BAR_TYPES } from '@klenty/klenty-ui';
import { useHistory } from "react-router-dom";
import { ReactComponent as AddUser } from "../../../assets/img/add-user.svg";
import { ReactComponent as View }  from "../../../assets/img/view.svg";
import SearchableDropdown from '../../../components/SingleSelect';
import { allowedEmails } from '../../../utils/constants';

interface ClusterProps {
    id: string
    clusterName: string;
    info: {
        console: {
            target: string;
        }
    }
}
function ClusterSelection({userId ,role, emailId} : {userId:string , role: string, emailId: string}) {
    let cancelToken  = useRef<null | CancelTokenSource>(null);
    const [availableClusters, setAvailableClusters] = useState<Array<ClusterProps>>([]);
    const [users, setUsers] = useState([]);
    const [searchVisibility, setSearchVisibility] = useState(true);
    const [query, setQuery] = useState("");
    const [addUserModal,setAddUserModal] = useState<boolean>(false);
    const [addUserLoading,setAddUserLoading] = useState<boolean>(false);
    const [username,setUsername] = useState<string>("");
    const [password,setPassword] = useState<string>("");

    const history = useHistory();

    useEffect(() => {
        const getAvailableClusters = async() => {
            await axios.get('/api/clusters/fetchClusterDetails').then((response) => {
                if(response.status === 200 && response.data){
                    setAvailableClusters(response.data);
                    setSearchVisibility(false);
                }
            })
        }

        getAvailableClusters();
    },[]);


    const searchProspects = async(username: string) => {
        setQuery(username);
        if(username !== ''){
            if(cancelToken.current) (cancelToken.current as CancelTokenSource).cancel("request cancelled");
            cancelToken.current = axios.CancelToken.source();
            console.log(username);
            const url = `/api/clusters/fetchUserByUserName?user=${username}`;
            await axios.get(url, { cancelToken: cancelToken.current.token }).then((response) => {
                if(response.data.data){
                    setUsers(response.data.data);
                }
            })
        }
    }

    const onSelectProspect = (prospect: Record<string, string>) => {
        const path = availableClusters
                        .filter(cluster => cluster.clusterName === prospect.clusterId)
                        .map(result => result.info.console.target)[0]
        setQuery(prospect.username);
        if(path !== ''){
            window.location.href = path + "/users/" + prospect.username + '/' + prospect.company;
        }
    }

    const addUserToconsole = async () => {
        setAddUserLoading(true); 
        try {
            await axios.post("/api/users/addConsoleUser",{
                username,
                password,
                addedBy:userId,
            })
         setAddUserLoading(false);
         setAddUserModal(false);
         setUsername("");
         setPassword("");
         showMessageBar({
            type: MESSAGE_BAR_TYPES.SUCCESS,
            heading: 'User created successfully',
          });
        }catch(err){
            showMessageBar({
                type: MESSAGE_BAR_TYPES.DANGER,
                heading: 'Something went wrong',
              });
         setAddUserLoading(false);
        }
    };

    return (
        <div className='Page'>
            <div className='top-bar'> 
             <div>
            {/* disabled visibility temporaily */}
             {false && role === "SUPERADMIN" &&
                <Tooltip content='add user' position={TOOLTIP_POSITIONS.BOTTOM}>
                  <span className='add-user' onClick={() => setAddUserModal(true)}>
                    <AddUser width={20}/>
                  </span>
                </Tooltip>
             }
            <Tooltip content='health dashboard' position={TOOLTIP_POSITIONS.BOTTOM}>
              <span className='health-view' onClick={() => history.push('/healthDashboard')}>
                <View width={20}/>
              </span>
            </Tooltip>
             </div>
            </div>
            {addUserModal &&
              <Modal open = {addUserModal}
             title={"Add user"} 
             onCancel = {() => {setAddUserModal(false);setUsername("");setPassword("")}}
             onOK = {addUserToconsole}
             okText = "Add"
             okButtonProps={{ loading:addUserLoading,disabled : username.trim().length && password.trim().length ? false : true}}
             cancelButtonProps ={{disabled:addUserLoading}}
             type={MODAL_TYPES.DEFAULT}
             size = {MODAL_SIZES.SMALL}
            >
            <div className='add-user-form'>
                <div className='form-field'>
                  <div className='field-name'>Username</div>
                  <TextInput onChange={setUsername} placeholder = {"username"} value = {username}></TextInput>
                </div>
                <div className='form-field'>
                   <div className='field-name'> Password</div>
                   <TextInput onChange={ setPassword} placeholder = {"password"} value = {password} type = {TEXT_INPUT_TYPES.PASSWORD}/>
                </div>
            </div>
             </Modal>
            }
            <img src={newLogo} width={100} height={120} alt="new_logo" />
            <h3>
                Klenty Support Console
            </h3>
            <div style={{margin: '0px 0px 20px 0px '}}>
                <SearchableDropdown
                options={users}
                query = {query}
                label="name"
                id="id"
                handleChange={searchProspects}
                disabled={searchVisibility}
                onSelect={(user: any)=> onSelectProspect(user) }
                />
            </div>
            <div>
                <div className="DashBoards_Container">
                    <div className='DashBoards_Header'>
                        Available Dashboards 
                    </div>
                    <div className='Card_Container'>
                        {
                            availableClusters.map((CardTitle, index) => (
                                <Card title={CardTitle.clusterName} key={index.toString()} path={CardTitle.info.console.target}/>
                            ))
                        }
                    </div>
                    {allowedEmails.includes(emailId) && <div className='AI_Prompt_Dashboard_Container'><Button variant={BUTTON_VARIANT.NORMAL} type={BUTTON_TYPES.PRIMARY} onClick={() => history.push('/aiPromptDashboard')}>AI Prompt Dashboard</Button></div>}
                </div>
            </div>
        </div>
    )
}

export default ClusterSelection;
