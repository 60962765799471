import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Button, BUTTON_TYPES, BUTTON_VARIANT, Dropdown, MESSAGE_BAR_TYPES, Modal, MODAL_SIZES, Pagination, Panel, showMessageBar, SingleSelect, Table, TextAreaInput, TextInput } from "@klenty/klenty-ui";
import { CellProps, Column } from "react-table";
import "./aiPromptDashboard.css";
import { ReactComponent as ChevronDown } from "../../../assets/img/chevronDown.svg";
import { ReactComponent as Close } from "../../../assets/img/close.svg";
import { ReactComponent as Edit } from "../../../assets/img/edit.svg";
import { aiPromptDashboardCategories, allowedEmails } from "../../../utils/constants";

interface IDescriptionModalProps {
  showDescriptionModal: boolean;
  setShowDescriptionModal: (value: boolean) => void
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void
  sortAndUpdateAIPromptDashboardData: (value: Record<string, string | boolean>[]) => void
  aiPromptDashboardData: Record<string, string | boolean>[],
}

function AIPromptDashboard({ emailId }: { emailId: string }) {
  const [selectedCategories, setSelectedCategories] = useState<{ label: string, value: string }[]>([]);
  const [aiPromptDashboardData, setAIPromptDashboardData] = useState<Record<string, string | boolean>[]>([]);
  const [editedRowData, setEditedRowData] = useState<Record<string, string | boolean> | null>(null);
  const [state, setState] = useState({
    isLoading: false,
    isError: false,
    isDisabled: false,
    categoryDropdownOpen: false,
    showDescriptionPanel: false,
    showDescriptionModal: false,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const setSpecificState = (key: keyof typeof state, value: boolean) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const categoryMap = useMemo(() => {
    return new Map(aiPromptDashboardCategories.map((cat) => [cat.value, cat.label]));
  }, []);

  const sortAndUpdateAIPromptDashboardData = (data: Record<string, string | boolean>[]) => {
    setAIPromptDashboardData([...data, { name: "", category: '', description: "", isAddRow: true } as Record<string, string | boolean>].sort((a: Record<string, string | boolean>, b: Record<string, string | boolean>) => {
      if (a.isAddRow) return 1;
      if (b.isAddRow) return -1;
      return (a.category as string).localeCompare(b.category as string);
    }));
  }

  useEffect(() => {
    setSpecificState('isLoading', true);
    axios.get(`/api/agents/agentPromptMeta`, {
      params: {
        page: currentPage,
        limit: 30,
        categories: selectedCategories.length ? selectedCategories.map(cat => cat.value).join(',') : null
      }
    }).then((response) => {
      const { data, count } = response.data;
      if (response.status === 200 && data && data.length) {
        sortAndUpdateAIPromptDashboardData(data);
        setTotalPages(Math.ceil((count || 0) / 30));
      }
      else {
        setAIPromptDashboardData([]);
      }
    })
      .catch((error) => {
        setSpecificState('isError', true);
        showMessageBar({
          type: MESSAGE_BAR_TYPES.DANGER,
          heading: 'Something went wrong',
        });
      })
      .finally(() => setSpecificState('isLoading', false));
  }, [currentPage, selectedCategories]);

  const generateColors = (categories: string[]) => {
    const colors = [
      "#FFAD29", "#2EABFF", "#4caf50", "#9c27b0", "#f44336", "#3f51b5", "#009688", "#795548",
    ];
    const colorMap: Record<string, string> = {};
    categories.forEach((category, index) => {
      colorMap[category] = colors[index % colors.length];
    });
    return colorMap;
  };

  const dotColors = generateColors(Array.from(categoryMap.values()));

  const columns: Column<Record<string, string | boolean>>[] = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }: CellProps<Record<string, string | boolean>>) => {
        if (row.original.isAddRow) {
          return (
            <div style={{ backgroundColor: "#F5F5F7", }}></div>
          );
        }

        const color = dotColors[categoryMap.get(row.original.category as string) as string] || "#000";

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: color,
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            ></div>
            {row.original.name}
          </div>
        );
      },
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: ({ row }: CellProps<Record<string, string | boolean>>) => {
        if (row.original.isAddRow) return (<div style={{ backgroundColor: "#F5F5F7", }}></div>);
        return <span>{row.original.category}</span>;
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ row }: CellProps<Record<string, string | boolean>>) => {
        if (row.original.isAddRow) {
          return (
            <div
              style={{
                backgroundColor: "#F5F5F7",
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
                padding: "16px",
                fontSize: "12px",
                lineHeight: "16px",
                color: '#080C2BCC',
                fontWeight: '500'
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                setSpecificState('showDescriptionModal', !state.showDescriptionModal);
              }}
            >
              <span style={{
                color: "#080C2B99",
                marginRight: "2px",
              }}>+</span>Add Row
            </div>);
        }
        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "flex-start",
              height: "100%",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              const icon = e.currentTarget.querySelector(".edit-icon") as HTMLElement;
              if (icon) icon.style.visibility = "visible";
            }}
            onMouseLeave={(e) => {
              const icon = e.currentTarget.querySelector(".edit-icon") as HTMLElement;
              if (icon) icon.style.visibility = "hidden";
            }}
          >
            <span
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxHeight: "2.5em",
                lineHeight: "1.2em",
                flex: 1,
              }}
            >
              {row.original.description}
            </span>
            <div
              className="edit-icon"
              style={{
                position: "absolute",
                right: "0px",
                visibility: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "64px",
                height: "30px",
                backgroundColor: "#fff"
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                setEditedRowData(row.original);
                setSpecificState('showDescriptionPanel', !state.showDescriptionPanel);
              }}
            >
              <Edit width={16} height={16} />
            </div>
          </div>
        );
      },
    },
  ];

  const handleSelect = (selected: { label: string, value: string }[]) => {
    setSelectedCategories(selected);
    setCurrentPage(1);
  };

  const handleRemove = (categoryToRemove: { label: string, value: string }) => {
    setSelectedCategories((prev) =>
      prev.filter((category) => category.value.toLowerCase() !== categoryToRemove.value.toLowerCase())
    );
    setCurrentPage(1);
    setSpecificState('categoryDropdownOpen', false);
  };

  const handleSaveEditedData = async () => {
    if (!editedRowData?.description || !editedRowData?._id || !editedRowData?.id) {
      showMessageBar({
        type: MESSAGE_BAR_TYPES.WARNING,
        heading: "Description is required!",
      });
      return;
    }

    setSpecificState('isDisabled', true);
    await axios.put(`/api/agents/agentPromptMeta`, { _id: editedRowData._id, id: editedRowData.id, description: editedRowData.description, category: editedRowData.category, name: editedRowData.name })
      .then((response) => {
        if (response.status === 200) {
          const updatedData = aiPromptDashboardData.map((item: Record<string, string | boolean>) =>
            item._id === editedRowData._id ? { ...editedRowData } : item
          );

          sortAndUpdateAIPromptDashboardData(updatedData.slice(0, -1));
          setSpecificState('showDescriptionPanel', false);
          showMessageBar({
            type: MESSAGE_BAR_TYPES.SUCCESS,
            heading: "Description updated successfully!",
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          let message = '';
          if (status === 400) {
            message = error.response.data.message || 'Bad request';
          } else if (status === 500) {
            message = 'Something went wrong';
          } else {
            message = error.response.data.message || 'An unexpected error occurred';
          }
          showMessageBar({
            type: MESSAGE_BAR_TYPES.DANGER,
            heading: message,
          });
        } else {
          showMessageBar({
            type: MESSAGE_BAR_TYPES.DANGER,
            heading: 'Something went wrong',
          });
        }
      })
      .finally(() => setSpecificState('isDisabled', false));
  };

  return (
    allowedEmails.includes(emailId) ? (state.isLoading ? <div className="Loading-page">Loading...</div> :
      state.isError ? (<section className="ai-prompt-dashboard__error-container">
        <div className="ai-prompt-dashboard__header">AI Prompt Dashboard</div>
        <div className="ai-prompt-dashboard__error-message">
          <p>Something went wrong. Please try again later.</p>
        </div>
      </section>) :
        ((!selectedCategories.length && !aiPromptDashboardData.length) || (selectedCategories.length === aiPromptDashboardCategories.length && !aiPromptDashboardData) ?
          <>
            <DescriptionModal showDescriptionModal={state.showDescriptionModal} setShowDescriptionModal={(value: boolean) => setSpecificState('showDescriptionModal', value)} isDisabled={state.isDisabled} setIsDisabled={(value: boolean) => setSpecificState('isDisabled', value)} sortAndUpdateAIPromptDashboardData={sortAndUpdateAIPromptDashboardData} aiPromptDashboardData={aiPromptDashboardData} />
            <section className="ai-prompt-dashboard__no-result-container">
              <div className="ai-prompt-dashboard__header">AI Prompt Dashboard</div>
              <div className="ai-prompt-dashboard__no-result-message">
                <p>No results found. It seems there is no data available currently.</p>
                <p>please try adding new data.</p>
                <div className="ai-prompt-dashboard__no-result-btn">
                  <Button
                    variant={BUTTON_VARIANT.NORMAL} type={BUTTON_TYPES.PRIMARY}
                    onClick={() => setSpecificState('showDescriptionModal', !state.showDescriptionModal)}
                  >
                    Add Data
                  </Button>
                </div>
              </div>
            </section>
          </>
          :
          <section className="ai-prompt-dashboard">
            <div className="ai-prompt-dashboard__header">AI Prompt Dashboard</div>
            <div className="flex-between">
            <Dropdown
              displayKey="label"
              uniqueKey="value"
              isMulti={true}
              open={state.categoryDropdownOpen}
              value={selectedCategories}
              targetFullWidth
              options={aiPromptDashboardCategories}
              optionsRendererHeight={500}
              onSelect={handleSelect}
              onClose={() => {
                setSpecificState('categoryDropdownOpen', false);
              }}
              onClick={() =>
                setSpecificState('categoryDropdownOpen', !state.categoryDropdownOpen)
              }
            >
              <div className="ai-prompt-dashboard__filter">
                <div className="ai-prompt-dashboard__filter-label">Filter by Category:</div>
                <div className="ai-prompt-dashboard__filter-list">
                  {selectedCategories.slice(0, 2).map((category: { label: string, value: string }) => (
                    <div key={category.value} className="ai-prompt-dashboard__filter-item">
                      <div className="ai-prompt-dashboard__filter-item-label">{category.label}</div>
                      <Close width={10} height={10} className="ai-prompt-dashboard__filter-item-icon" onClick={(event) => {
                        event.stopPropagation();
                        handleRemove(category);
                      }} />
                    </div>
                  ))}
                  {(selectedCategories.length - 2) > 0 && (
                    <div className="ai-prompt-dashboard__filter-item ai-prompt-dashboard__filter-item-summary">
                      +{selectedCategories.length - 2} selected
                    </div>
                  )}
                </div>
                <ChevronDown width={10} height={10} className="ai-prompt-dashboard__filter-icon" />
              </div>
            </Dropdown>
            <button
            className="add-new_buton"
             onClick={() => {
              setSpecificState('showDescriptionModal', !state.showDescriptionModal);
            }}
            >
              Add New
            </button>
            </div>
            {(selectedCategories.length && !aiPromptDashboardData.length) ?
              <section className="ai-prompt-dashboard__no-result-container" style={{minHeight: "70vh", background: "white"}}>
                <div className="ai-prompt-dashboard__no-result-message">
                  <p>No data found for the applied filters.</p>
                  <p>Please check your filters or try adjusting them to find relevant data.</p>
                </div>
              </section> :
              <>
                <Table<Record<string, string | boolean>>
                  className="custom-table"
                  columns={columns}
                  data={aiPromptDashboardData}
                  pageSize={31}
                  hasRowSelect={false}
                  hasAllRowSelect={false}
                />
                <Pagination
                  dataType="aiPromptDashboard"
                  pageSize={30}
                  setPageSize={() => { }}
                  currentPageIndex={currentPage - 1}
                  totalPage={totalPages}
                  previousPage={() => setCurrentPage((prev) => prev - 1)}
                  nextPage={() => setCurrentPage((prev) => prev + 1)}
                  goToPage={(pageIndex: number) => setCurrentPage(pageIndex + 1)}
                  canNextPage={currentPage < totalPages}
                  canPreviousPage={currentPage > 1}
                />
              </>}

            {state.showDescriptionPanel &&
              <Panel
                className="description-panel__container"
                headerRenderer={() => <div className="description-panel__header">Description</div>}
                footerRenderer={() => <></>}
              >
                <div className="description-panel__content">
                  <TextAreaInput
                    fullWidth={true}
                    minHeight="408px"
                    value={editedRowData?.description as string || ""}
                    classname="description-panel__textarea"
                    onChange={(value) => setEditedRowData((prev) => ({ ...prev, description: value }))}
                  />
                  <div className="description-panel_actions actions-container">
                    <Button
                      className="description-panel__button-cancel"
                      onClick={() => setSpecificState('showDescriptionPanel', false)}
                      disabled={state.isDisabled}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="description-panel__button-save"
                      onClick={() => handleSaveEditedData()}
                      disabled={state.isDisabled}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Panel>
            }
            <DescriptionModal showDescriptionModal={state.showDescriptionModal} setShowDescriptionModal={(value: boolean) => setSpecificState('showDescriptionModal', value)} isDisabled={state.isDisabled} setIsDisabled={(value: boolean) => setSpecificState('isDisabled', value)} sortAndUpdateAIPromptDashboardData={sortAndUpdateAIPromptDashboardData} aiPromptDashboardData={aiPromptDashboardData} />
          </section>

        )
    ) : <div className="ai-prompt-dashboard__no-result-container">You do not have the necessary permissions to view this content.</div>
  )
}

export default AIPromptDashboard;

function DescriptionModal({ showDescriptionModal, setShowDescriptionModal, isDisabled, setIsDisabled, sortAndUpdateAIPromptDashboardData, aiPromptDashboardData }: IDescriptionModalProps) {
  const [inputFields, setInputFields] = useState<{
    name: string;
    category: { label: string; value: string } | null;
    description: string;
  }>({
    name: '',
    category: null,
    description: ''
  });

  const handleAddAIPromptDashboardData = async () => {
    if (!inputFields.category || !inputFields.description || !inputFields.name) {
      showMessageBar({
        type: MESSAGE_BAR_TYPES.WARNING,
        heading: 'All fields are required.',
      });
      return;
    }
    setIsDisabled(true);
    await axios.post('/api/agents/agentPromptMeta', {
      name: inputFields.name,
      category: inputFields.category.value,
      description: inputFields.description,
    }).then((response) => {
      if (response.status === 200) {
        const { _id, id, name, category, description } = response.data.data;
        let updatedDashboardData = aiPromptDashboardData.slice(0, -1);
        if (aiPromptDashboardData.length > 30) {
          updatedDashboardData = updatedDashboardData.slice(0, -1);
        }
        sortAndUpdateAIPromptDashboardData([{ _id, id, name, category, description }, ...updatedDashboardData]);
        showMessageBar({
          type: MESSAGE_BAR_TYPES.SUCCESS,
          heading: `${inputFields?.category?.label} added successfully!`,
        });
      }
    })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          let message = '';
          if (status === 400) {
            message = error.response.data.message || 'Bad request';
          } else if (status === 500) {
            message = 'Something went wrong';
          } else {
            message = error.response.data.message || 'An unexpected error occurred';
          }
          showMessageBar({
            type: MESSAGE_BAR_TYPES.DANGER,
            heading: message,
          });
        } else {
          showMessageBar({
            type: MESSAGE_BAR_TYPES.DANGER,
            heading: 'Something went wrong',
          });
        }
      })
      .finally(() => {
        setInputFields({
          name: '',
          category: null,
          description: ''
        });
        setIsDisabled(false);
        setShowDescriptionModal(false);
      });
  }

  const handleInputFieldChange = (field: string, value: string) => {
    setInputFields((prev) => ({
      ...prev,
      [field]: value
    }));
  }

  return (<Modal
    title="Description"
    size={MODAL_SIZES.LARGE}
    open={showDescriptionModal}
    width="80%"
    defaultFixedHeight="700px"
    footerRenderer={() => (
      <div className="description-modal_actions actions-container" style={{ padding: "16px 24px" }}>
        <Button
          className="description-modal__button-cancel"
          onClick={() => setShowDescriptionModal(false)}
          disabled={isDisabled}
        >
          Cancel
        </Button>
        <Button
          className="description-modal__button-save"
          onClick={handleAddAIPromptDashboardData}
          disabled={isDisabled}
        >
          Save
        </Button>
      </div>
    )}>
    <div className="description-modal__form-container">
      <div className="description-modal__form-input-container">
        <TextInput fullWidth label="Name" placeholder={'Enter the Name'} value={inputFields.name} onChange={(value) => handleInputFieldChange('name', value)} />
        <SingleSelect fullWidth label="Category" placeholder={'Enter the Category'} value={inputFields.category} onSelect={(category) => handleInputFieldChange('category', category)} options={aiPromptDashboardCategories} displayKey="label" uniqueKey="value" />
      </div>
      <div className="description-modal__textarea">
        <div className="description-modal__textarea-label">Description</div>
        <TextAreaInput
          fullWidth
          placeholder="Enter the Description"
          value={inputFields.description}
          minHeight="510px"
          onChange={(value) => handleInputFieldChange('description', value)}
        />
      </div>
    </div>
  </Modal>)
}