import React, { useEffect, useState } from "react";
import Login from "./app/pages/login/login";
import ClusterSelection from "./app/pages/cluster-selection/clusterSelection";
import axios from "axios";

import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HealthDashboard from "./app/pages/healthCheck";
import AIPromptDashboard from "./app/pages/aiPromptDashboard";

type userType = {
  role:string,
  userId:string,
  emailId: string
}

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user , setUser] = useState<userType>({role:"",userId:"", emailId: ""});

  useEffect(() => {
    const isAuthenticated = async () => {
      return await axios.get("/api/auth/authenticated").then((res) => {
        setUser({userId:res.data.userId , role:res.data.role, emailId: res.data.emailId});
        if (res.data.status) {
          setLoginState(res.data.status);
        } else {
          setLoginState(false);
        }
        setLoading(false);
      });
    };
    isAuthenticated();
  },[]);

  const setLoginState = (status: boolean) => {
    setAuthenticated(status);
  };
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/healthDashboard">
            {authenticated ? (
              <HealthDashboard />
            ) : (
              <Login
                setLoginState={(status: boolean) => setLoginState(status)}
                setUser = {setUser}
              />
            )}
          </Route>

          <Route path="/aiPromptDashboard">
            {authenticated ? (
              <AIPromptDashboard emailId={user.emailId} />
            ) : (
              <Login
                setLoginState={(status: boolean) => setLoginState(status)}
                setUser = {setUser}
              />
            )}
          </Route>

          <Route path="/">
            <>
              {loading ? (
                <div className="Loading-page">Loading...</div>
              ) : authenticated ? (
                <ClusterSelection userId = {user.userId} role = {user.role} emailId = {user.emailId} />
              ) : (
                <Login
                  setLoginState={(status: boolean) => setLoginState(status)}
                  setUser = {setUser}
                />
              )}
            </>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
