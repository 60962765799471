export const serverNameMapping: any = {
  webApp: "Web App",
  webAppBlue: "Web App Blue",
  authService: "Auth Service",
  redisServer: "Redis Server",
  smtpServer: "SMTP Server",
  production_replytracker_1: "RT Server 1",
  production_replytracker_2: "RT Server 2",
  production_replytracker_3: "RT Server 3",
  production_replytracker_4: "RT Server 4",
  production_replytracker_5: "RT Server 5",
  production_replytracker_6: "RT Server 6",
};

export const aiPromptDashboardCategories: {label: string, value:string}[] = [
    { label: 'Framework', value: 'FRAMEWORK' },
    { label: 'Email Tone', value: 'EMAIL_TONE' },
    { label: 'Outcome', value: 'OUTCOME' },
    { label: 'Key Message', value: 'KEY_MESSAGE' },
];

export const allowedEmails = ["akshay@klenty.com", "sanjana@klenty.com", "vengat@klenty.com", "rajesh@klenty.com", "babiyos@klenty.com"];
